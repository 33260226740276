<template>
  <div class="view-box">
    <div class="view-box-title">清运统计
      <a-radio-group default-value="car">
        <a-radio value="car" @click="typeClick('car')">
          <span style="color: #ffffff">按车辆</span>
        </a-radio>
        <a-radio value="driver" @click="typeClick('driver')">
          <span style="color: #ffffff">按司机</span>
        </a-radio>
      </a-radio-group>
      <div style="float: right">
        <!--        <a-button type="primary" ghost shape="round" @click="dateClick('yesterday')">昨日</a-button>-->
        <!--<a-button style="margin-left: 10px;background: #00A0E9;" ghost type="primary"  shape="round" @click="dateClick('currentWeek')">本周</a-button>
        <a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentMonth')">本月</a-button>-->
        <a-radio-group default-value="currentMonth" size="small" button-style="solid">
          <a-radio-button value="currentWeek" @click="dateClick('currentWeek')">
            近7日
          </a-radio-button>
          <a-radio-button value="currentMonth" @click="dateClick('currentMonth')">
            近30日
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div class="view-box-content" id="scroll-board" style="padding: 0px;height: 265px">
      <dv-scroll-board :config="config" style="height: 260px"/>
    </div>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'

  export default {
    name: 'RightThreeList',
    data () {
      return {
        config: {
          header: ['车牌号', '载重', '清运趟数', '拉运量', '拉运次数', '拉运公里数'],
          data: [
            ['宁A65845', '清运中', '13吨', '256吨', '15次', '230公里'],
            ['宁A13254 ', '空闲', '10吨', '342吨', '18次', '230公里'],
            ['宁AG1615', '清运中', '5吨', '58吨', '6次', '230公里'],
            ['宁A28802', '清运中', '13吨', '12吨', '4次', '150公里'],
            ['宁A29336', '空闲', '5吨', '350吨', '20次', '239公里'],
            ['宁A65845', '清运中', '13吨', '256吨', '15次', '230公里'],
            ['宁A13254 ', '空闲', '10吨', '342吨', '18次', '230公里'],
            ['宁AG1615', '清运中', '5吨', '58吨', '6次', '230公里'],
            ['宁A28802', '清运中', '13吨', '12吨', '4次', '150公里'],
            ['宁A29336', '空闲', '5吨', '350吨', '20次', '239公里']
          ],
          index: false,
          columnWidth: [100, 100, 100, 100, 100, 100],
          align: ['center'],
          headerHeight: 35,
          rowNum: 8,
          carousel: 'page',
          headerBGC: 'rgba(0, 44, 81, 0.8)',
          oddRowBGC: 'rgba(0, 44, 81, 0.8)',
          evenRowBGC: 'rgba(10, 29, 50, 0.8)'
        },
        type: 'car', //按司机还是按车辆统计
        startTime: '',
        endTime: ''
      }
    },
    created () {
      this.startTime = moment().subtract(30, 'days').format('YYYY-MM-DD')
      this.endTime = moment().format('YYYY-MM-DD')
      this.getCarStatistics(this.type)
    },
    methods: {
      typeClick (value) {
        this.type = value
        this.getCarStatistics()
      },
      getCarStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getCarStatistics,
          data: {
            type: this.type,
            startTime: this.startTime,
            endTime: this.endTime
          },
          noTips: true,
          loading: 'no',
          success: (data) => {
            if (this.type === 'car') {
              this.config = {
                header: ['车牌号', '载重', '清运趟数', '拉运量', '任务个数', '拉运公里数'],
                data: data.body,
                index: false,
                columnWidth: [100, 100, 100, 100, 100, 100],
                align: ['center'],
                headerHeight: 35,
                rowNum: 6,
                carousel: 'page',
                headerBGC: 'rgba(0, 44, 81, 0.8)',
                oddRowBGC: 'rgba(0, 44, 81, 0.8)',
                evenRowBGC: 'rgba(10, 29, 50, 0.8)'
              }
            } else if (this.type === 'driver') {
              this.config = {
                header: ['司机姓名', '清运趟数', '拉运量', '任务个数', '拉运公里数'],
                data: data.body,
                index: false,
                columnWidth: [120, 100, 140, 100, 140],
                align: ['center'],
                headerHeight: 35,
                rowNum: 6,
                carousel: 'page',
                headerBGC: 'rgba(0, 44, 81, 0.8)',
                oddRowBGC: 'rgba(0, 44, 81, 0.8)',
                evenRowBGC: 'rgba(10, 29, 50, 0.8)'
              }
            }
          },
          error: () => {
          }
        })
      },
      dateClick (type) {
        if (type === 'yesterday') {
          this.startTime = moment().subtract(1, 'd').format('YYYY-MM-DD')
          this.endTime = moment().subtract(1, 'd').format('YYYY-MM-DD')
        } else if (type === 'currentWeek') {
          // this.startTime = moment().startOf('week').format('YYYY-MM-DD')
          // this.endTime = moment().format('YYYY-MM-DD')
          this.startTime = moment().subtract(7, 'days').format('YYYY-MM-DD')
          this.endTime = moment().format('YYYY-MM-DD')
        } else if (type === 'currentMonth') {
          // this.startTime = moment().startOf('month').format('YYYY-MM-DD')
          // this.endTime = moment().format('YYYY-MM-DD')
          this.startTime = moment().subtract(30, 'days').format('YYYY-MM-DD')
          this.endTime = moment().format('YYYY-MM-DD')
        }
        this.getCarStatistics()
      }
    }
  }
</script>

<style lang="less">
  #scroll-board {
    height: 332px;
    overflow: hidden;
  }

  .layout {
    .ant-layout {
      .header {
        height: auto;
      }
    }
  }
</style>
