<template>
  <div class="content m-t-24">
    <div class="content-col">
      <div class="view-box">
        <div class="view-box-title">清运统计
          <div style="float: right">
            <!--<a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentWeek')">近7日</a-button>
            <a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentWeek')">近30日</a-button>
            <a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentMonth')">本年</a-button>-->
            <a-radio-group default-value="currentMonth" size="small" button-style="solid">
              <a-radio-button value="currentWeek" @click="typeClick('sevenDays')">
                近7日
              </a-radio-button>
              <a-radio-button value="currentMonth" @click="typeClick('thirtyDays')">
                近30日
              </a-radio-button>
<!--              <a-radio-button value="currentYear" @click="typeClick('currentYear')">-->
<!--                本年-->
<!--              </a-radio-button>-->
            </a-radio-group>
          </div>
        </div>
      </div>
      <div class="view-content-box">
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('sb')">
          <div class="content" style="padding: 0;">
            <span class="card-title">报抽次数</span><br>
            <span class="card-value" style="font-size: 15px;">{{reportNum}}<span class="unit"> 次</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('ts')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运趟数</span><br>
            <span class="card-value" style="font-size: 15px;">{{loopNum}}<span class="unit"> 趟</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('lc')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运里程</span><br>
            <span class="card-value" style="font-size: 15px;">{{mileageNum}}<span class="unit"> km</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('ds')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运吨数</span><br>
            <span class="card-value" style="font-size: 15px;">{{tonNum}}<span class="unit"> 吨</span></span><br>
          </div>
        </div>
      </div>
      <!--<div class="view-content-box" style="width: 100%;padding: 0;">
        <div :color="['#071d46', '#1957dc']" :reverse="true" class="data-card"
             style="width: 22%;cursor: pointer" @click="onClick('sb')">
          <div class="content">
            <span class="card-title" style="font-size: 12px">报抽数量：</span>
            <span class="card-value" style="font-size: 13px">{{reportNum}}<span class="unit">次</span></span><br>
          </div>
        </div>
        <div :color="['#071d46', '#1957dc']" :reverse="true" class="data-card"
             style="width: 22%;cursor: pointer;"
             @click="onClick('ts')">
          <div class="content">
            <span class="card-title" style="font-size: 12px">拉运趟数：</span>
            <span class="card-value" style="font-size: 13px">{{loopNum}}<span class="unit">趟</span></span><br>
          </div>
        </div>
        <div :color="['#071d46', '#1957dc']" :reverse="true" class="data-card" style="width: 27%;cursor: pointer"
             @click="onClick('lc')">
          <div class="content">
            <span class="card-title" style="font-size: 12px">拉运里程：</span>
            <span class="card-value" style="font-size: 13px">{{mileageNum}}<span class="unit">km</span></span><br>
          </div>
        </div>
        <div :color="['#071d46', '#1957dc']" :reverse="true" class="data-card" style="width: 23%;cursor: pointer"
             @click="onClick('ds')">
          <div class="content">
            <span class="card-title" style="font-size: 12px">拉运吨数：</span>
            <span class="card-value" style="font-size: 13px">{{tonNum}}<span class="unit">吨</span></span><br>
          </div>
        </div>
      </div>-->
      <div style="clear: both;margin-top: 10px">
        <div id="line" style="width: 100%;height: 335px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import echarts from 'echarts'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'RightTwoLine',
    props: {
      time: Array
    },
    data () {
      return {
        reportNum: 0,
        loopNum: 0,
        mileageNum: 0,
        tonNum: 0,
        charts: null,
        xAxisData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        data: [19, 23, 18, 22, 25, 24, 26, 21, 17, 24, 0, 0],
        seriesName: '次数',
        text: '报抽数量统计',
        reportData: [], //报抽数量统计曲线数据
        type: 'thirtyDays' //查询类型（近7日、近30日、当年）
      }
    },
    mounted () {
      this.getRecordStatistics()
    },
    methods: {
      typeClick (value) {
        this.type = value
        this.getRecordStatistics()
      },
      getRecordStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getRecordStatistics,
          noTips: true,
          data: {
            type: this.type
          },
          loading: 'no',
          success: (data) => {
            this.reportNum = data.body.reportNum
            this.loopNum = data.body.loopNum
            this.mileageNum = data.body.mileageNum
            this.tonNum = data.body.tonNum
            this.reportData = data.body.reportData
            this.data = this.reportData
            this.recordData = data.body.recordData
            this.mileageData = data.body.mileageData
            this.tonData = data.body.tonData
            if (this.type === 'sevenDays' || this.type === 'thirtyDays') {
              this.xAxisData = data.body.xAxisData
            } else if (this.type === 'currentYear') {
              this.xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            }
            this.initLine()
          },
          error: () => {
          }
        })
      },
      initLine () {
        this.charts = echarts.init(document.getElementById('line'))
        this.charts.setOption({
          title: {
            show: true,
            text: this.text,
            textStyle: {
              color: 'rgb(246,255,253)',
              fontSize: '12'
            },
            top: 20,
            left: '40%'
          },
          grid: {
            left: '7%',
            right: 20,
            bottom: 30
          },
          xAxis: {
            show: true,
            type: 'category',   // 还有其他的type，可以去官网喵两眼哦
            data: this.xAxisData,   // x轴数据
            axisLine: {
              lineStyle: {
                color: 'rgba(250,255,248,0.77)'
              }
            }
          },
          yAxis: {
            min: '0',
            show: true,
            type: 'value',
            axisLine: {
              lineStyle: {
                color: 'rgba(250,255,248,0.77)'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(103,103,103,0.95)',
                type: 'dashed'
              }
            }
          },
          tooltip: {
            trigger: 'axis'   // axis   item   none三个值
          },
          series: [
            {
              name: this.seriesName,
              data: this.data,
              type: 'line',
              lineStyle: {
                color: 'rgba(15,204,255,0.77)'
              }
            }
          ]
        })
      },
      onClick (type) {
        if (type === 'sb') {
          this.data = this.reportData
          this.seriesName = '次数'
          this.text = '报抽次数统计'
        }
        if (type === 'lc') {
          this.data = this.mileageData
          this.seriesName = '公里'
          this.text = '拉运里程统计'
        }
        if (type === 'ds') {
          this.data = this.tonData
          this.seriesName = '吨'
          this.text = '拉运吨数统计'
        }
        if (type === 'ts') {
          this.data = this.recordData
          this.seriesName = '趟'
          this.text = '拉运趟数统计'
        }
        this.initLine()
      }
    }

  }
</script>
